<template>
    <HeadBar />
    <div>
        <h1 class="intro">Contact Us!</h1>
        <div class="form-shadow container">
            <form class="formcont" ref="form" @submit.prevent="sendEmail">
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name='from_name'>
                    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">How can we help you?</label>
                    <textarea type="password" class="form-control" id="exampleInputPassword1" name='message'></textarea>
                </div>
                <button type="submit" class="btn btn-success">Submit</button>
            </form>
        </div>
    </div>
    <FootBar/>
</template>

<style>
.intro {
    text-align: center;
    font-size: 5em;
    margin: 5vh;
}
.form-shadow {
    width: 50vw;
    border: 1px solid green;
    margin-bottom: 50px;
}
.formcont {
    margin: 4vw;
}
@media screen and (max-width: 500px) {
.form-shadow {
    width: 90vw;
    border: 1px solid green;
    margin-bottom: 50px;
  }
}
</style>

<script>
import HeadBar from '@/components/HeadBar.vue';
import FootBar from '@/components/FootBar.vue';
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';

export default {
  name: 'ContactPage',

  components: { HeadBar, FootBar },

  methods: {
    sendEmail() {
      emailjs
        .sendForm('service_6fgtutf', 'template_6ttzglg', this.$refs.form, {
          publicKey: 'gaqDvZ1uPiEy0Z2CO',
        })
        .then(
          () => {
            swal("Success!", `Message sent successfully!`, "success");
            document.getElementById('exampleInputEmail1').value = null;
            document.getElementById('exampleInputPassword1').value = null;
          },
          (error) => {
            swal("Oops!", `An error occurred: ${error.text}`, "error");
          },
        );
    },
}
}
</script>